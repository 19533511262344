import React, { useEffect, useRef } from 'react';
import { Carousel as TemplateCarousel } from '@catalogo/theme-carousel-with-bullets';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ZionSlideshow = ({ publication, renderComponent }) => {
  const { autoplay, children, arrow, publicationId, infinit } = publication;

  const carouselRef = useRef(null);
  useEffect(() => {
    if (autoplay === 'sim') {
      setInterval(() => triggerNextPage(), 4000);
    }
  }, []);

  const triggerNextPage = () => {
    !!carouselRef.current && carouselRef.current.handlePressNext();
  };

  return (
    <Wrapper>
      <TemplateCarousel
        ref={carouselRef}
        arrow={arrow === 'sim' ? true : false}
        bullets={arrow === 'sim' ? true : false}
        autoplay={autoplay === 'sim' ? true : false}
        infinit={infinit === 'sim' ? true : false}
      >
        {children &&
          children.map(child => (
            <React.Fragment key={`${publicationId}-${child._id}`}>
              {renderComponent({ ...child, zionSlideShow: true })}
            </React.Fragment>
          ))}
      </TemplateCarousel>
    </Wrapper>
  );
};

ZionSlideshow.propTypes = {
  publication: PropTypes.object,
  renderComponent: PropTypes.func,
};

export default ZionSlideshow;

const Wrapper = styled.div`
  margin-bottom: 22px;
  & .spacey-image {
    width: 1250px;
  }
`;

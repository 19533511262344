import React, { useEffect, useRef, useState } from 'react';
import { Carousel as TemplateCarousel } from '@catalogo/theme-carousel-with-bullets';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const ZionSlideshow = ({ publication, renderComponent }) => {
  const { autoplay, children, publicationId, infinit } = publication;
  const carouselRef = useRef(null);
  const [offsetWidth, setOffsetWidth] = useState(0);
  useEffect(() => {
    if (autoplay === 'sim') {
      setInterval(() => triggerNextPage(), 4000);
    }
  }, []);
  useEffect(() => {
    setOffsetWidth(carouselRef?.current?.shelfRef?.current?.offsetWidth || 0);
  }, [carouselRef]);

  const triggerNextPage = () => {
    !!carouselRef.current && carouselRef.current.handlePressNext();
  };

  return (
    <Wrapper width={offsetWidth}>
      <TemplateCarousel
        ref={carouselRef}
        bullets={true}
        arrow={false}
        autoplay={autoplay === 'sim' ? true : false}
        infinit={infinit === 'sim' ? true : false}
        itemsToShow={1}
      >
        {children &&
          children.map(child => (
            <React.Fragment key={`${publicationId}-${child._id}`}>
              {renderComponent({ ...child, zionSlideShow: true })}
            </React.Fragment>
          ))}
      </TemplateCarousel>
    </Wrapper>
  );
};

ZionSlideshow.propTypes = {
  publication: PropTypes.object,
  renderComponent: PropTypes.func,
};

export default ZionSlideshow;

const Wrapper = styled.div`
  ${({ width }) => css`
    margin-bottom: 22px;
    width: 100%;

    & .spacey-image,
    & .wrapper-b2wads {
      ${width &&
      css`
        width: ${width}px;
      `};
    }
  `}
`;
